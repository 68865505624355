<template>
  <div class="billing-success">
    <CircleLoader :ratio="1.5"/>
    <h1>The payment was successful,</h1>
    <p>you will be redirected to the workspace</p>
  </div>
</template>

<script>
import {useRouter} from "vue-router";
import CircleLoader from "@/components/UI-Kit/loaders/CircleLoader";
export default {
  name: "BillingSuccessPay",
  props: {
    workspaceId: String
  },
  components: {CircleLoader},
  setup (props) {
    const router = useRouter()

    setTimeout(() => {
      router.push({
        name: "Dashboard",
        params: {
          workspaceId: props.workspaceId
        }
      })
    }, 3000)
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/styles/color-scheme.scss";
.billing-success {
  padding-top: 40px;
  background-color: $pxp-gray-accent-color-dimmer;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

</style>